.vjs-internal {
	--video-js--primary: #eaea05;
}
.vjs-internal .vjs-big-play-button {
	width: 70px;
	height: 70px;
	background: 0 0;
	line-height: 180px;
	font-size: 180px;
	border: none;
	top: 50%;
	left: 50%;
	margin-top: -90px;
	color: rgba(255, 255, 255, 0.65);
}
.vjs-internal.vjs-big-play-button:focus,
.vjs-internal:hover .vjs-big-play-button {
	background-color: transparent;
	color: rgba(255, 255, 255, 1);
}
.vjs-internal .vjs-control-bar {
	top: calc(50% - 45px);
	height: 100px;
	width: 50px;
	background-color: #4d4d4d;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
.vjs-internal .vjs-button > .vjs-icon-placeholder:before {
	line-height: 50px;
}
.vjs-internal .vjs-play-progress:before {
	display: none;
}
.vjs-internal .vjs-progress-control {
	display: none;
}
.vjs-internal .vjs-paused,
.vjs-internal .vjs-playing {
	width: 50px;
	height: 50px;
	border-radius: 4px;
	background-color: #4d4d4d;
}
.vjs-internal .vjs-volume-panel {
	width: 50px;
	height: 50px;
	border-radius: 4px;
	padding-left: 4px;
	background-color: #4d4d4d;
	margin: 50px 0 0 -50px;
}
.vjs-internal .vjs-volume-panel .vjs-volume-control.vjs-volume-horizontal {
	padding-top: 1em;
	transition: visibility 1s, opacity 1s, height 1s 1s, width 1s, right 1s 1s,
		top 1s 1s;
}
.vjs-internal .vjs-volume-panel .vjs-volume-control.vjs-slider-active,
.vjs-internal .vjs-volume-panel .vjs-volume-control:active,
.vjs-internal
	.vjs-volume-panel.vjs-hover
	.vjs-mute-control
	~ .vjs-volume-control,
.vjs-internal .vjs-volume-panel.vjs-hover .vjs-volume-control,
.vjs-internal .vjs-volume-panel:active .vjs-volume-control,
.vjs-internal .vjs-volume-panel:focus .vjs-volume-control {
	visibility: visible;
	opacity: 1;
	position: relative;
	transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, right 0s,
		top 0s;
}
.vjs-internal .vjs-slider-horizontal .vjs-volume-level:before {
	top: -0.4em;
	right: -0.5em;
}
.vjs-internal .vjs-remaining-time {
	display: none;
}
.vjs-internal .vjs-live-display {
	display: none;
}
.vjs-internal .vjs-picture-in-picture-control {
	display: none;
}
.vjs-internal .vjs-fullscreen-control {
	display: none;
}
.vjs-internal .vjs-seek-to-live-control {
	display: none;
}
.vjs-internal .vjs-subs-caps-button {
	display: none;
}
.vjs-internal .vjs-custom-control-spacer {
	display: block;
	width: 100%;
}
